import { useProfiles } from '../../../context/profiles/use-profiles';
import { cn } from '../../../utils/cn';
import { EmptyList } from '../../empty-list';
import { ProfileCard } from '../athlete-card';

export const ProfilesList = () => {
	const { profiles } = useProfiles();

	if (!profiles || !profiles.length) {
		return <EmptyList />;
	}

	return (
		<div>
			<p className="mb-2 text-xs uppercase text-gray-400">{'Seleziona profilo'}</p>
			{profiles.map((profile, index) => (
				<div
					className={cn({
						'border-t border-solid border-gray-100': index > 0,
					})}
					key={profile._id}>
					<ProfileCard profile={profile} />
				</div>
			))}
		</div>
	);
};
