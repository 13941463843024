import { Avatar, AvatarFallback, AvatarImage } from '@/components/avatar';
import { Card } from '@/components/card';
import { Link } from 'react-router-dom';
import { LoadingLayout } from '../../../components';
import { Carousel } from '../../../components/carousel';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useSponsors } from '../../../context/sponsors';
import { CardsList } from './cards-list';

type UserHeaderProps = {
	firstName: string;
	lastName: string;
	avatarUrl?: string;
	organization: {
		name: string;
		logoUrl?: string;
	};
};

const UserHeader = (props: UserHeaderProps) => {
	const user = props;

	return (
		<Card className="bg-background flex items-center space-x-4 p-4">
			<div className="relative">
				<Avatar className="h-20 w-20">
					<AvatarImage src={user.avatarUrl} alt={`${user.firstName} ${user.lastName}`} />
					<AvatarFallback>
						{user.firstName[0]}
						{user.lastName[0]}
					</AvatarFallback>
				</Avatar>

				<Avatar className="absolute -bottom-1 -right-1 h-7 w-7 border border-solid border-slate-100 bg-white p-1">
					<AvatarImage src={user.organization.logoUrl} alt={user.organization.name} />
				</Avatar>
			</div>
			<div>
				<h2 className="text-xl font-semibold">
					{user.firstName} {user.lastName}
				</h2>
				<p className="text-muted-foreground text-sm">{user.organization.name}</p>
			</div>
		</Card>
	);
};

export const Home = () => {
	const { orgPerson, organization } = useOrgPerson();
	const { sponsors } = useSponsors();

	if (!orgPerson || !organization) {
		return <LoadingLayout />;
	}

	return (
		<div className={'flex h-full flex-col justify-between'}>
			<div>
				<Link to="/profile">
					<UserHeader
						firstName={orgPerson.name}
						lastName={orgPerson.surname}
						avatarUrl={orgPerson.personMetadata.avatar}
						organization={{
							name: organization.name,
							logoUrl: organization.logo_url,
						}}
					/>
				</Link>
				<CardsList orgPerson={orgPerson} />
			</div>
			<Carousel sponsors={sponsors} />
		</div>
	);
};
