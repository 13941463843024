import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { TObjTranche } from '@golee/gle-types';
import { DownloadIcon } from 'lucide-react';
import { useEffect } from 'react';
import { CashedTrancheInfo, NotCashedTrancheInfo, SkeletonButton } from '../../../components';
import { InvoiceDownloadButton } from '../../../components/invoice-button-download';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useReceipt } from '../../../context/tranches';
import { usePaymentInfo } from '../../../hooks/use-payment-info';
import { PaymentButton } from '../../public/pay/payment-button';
import { TrancheDetailLayout } from './tranche-detail-layout';

export const TrancheDetailNotCashed = ({ tranche }: { tranche: TObjTranche }) => {
	const { paymentInfo, loadPaymentInfo, isLoading } = usePaymentInfo();
	const { orgPerson } = useOrgPerson();

	useEffect(() => {
		if (orgPerson.organizationId && tranche._id) {
			void loadPaymentInfo(orgPerson.organizationId, tranche._id);
		}
	}, []);

	const getPrimaryCTA = () => {
		if (tranche.amount.total_amount === 0) {
			return (
				<Button disabled variant={'secondary'} size="lg">
					Pagamento in app non disponibile
				</Button>
			);
		}

		if (!paymentInfo) {
			return (
				<Button disabled variant={'secondary'} size="lg">
					Pagamento in app non disponibile
				</Button>
			);
		}

		return (
			<PaymentButton
				organizationId={orgPerson.organizationId}
				trancheId={tranche._id}
				availablePaymentMethods={paymentInfo.availablePaymentMethods}
			/>
		);
	};

	return (
		<TrancheDetailLayout
			tranche={tranche}
			PrimaryCTA={isLoading ? <SkeletonButton /> : <>{getPrimaryCTA()}</>}
			TrancheInfo={
				<NotCashedTrancheInfo
					tranche={tranche}
					suggestGoleePayActivation={!paymentInfo}
					isLoadingPaymentInfo={isLoading}
				/>
			}
		/>
	);
};

export const TrancheDetailCashed = ({ tranche }: { tranche: TObjTranche }) => {
	const { receipt } = useReceipt();

	return (
		<TrancheDetailLayout
			tranche={tranche}
			PrimaryCTA={
				<div>
					{tranche.invoice?.id && <InvoiceDownloadButton invoiceId={tranche.invoice.id} />}
					{tranche.receipt?.id && <ReceiptDownloadButton documentUrl={receipt?.documentUrl} />}
				</div>
			}
			TrancheInfo={<CashedTrancheInfo tranche={tranche} />}
		/>
	);
};

const ReceiptDownloadButton = ({ documentUrl }: { documentUrl?: string }) => {
	const { messages } = useI18n();
	const { trackEvent } = useConan();

	return !documentUrl ? (
		<SkeletonButton />
	) : (
		<a href={documentUrl} target={'_blank'}>
			<Button variant={'secondary'} onClick={() => trackEvent('family.receipt-opened')}>
				<DownloadIcon size={18} className="mr-2" /> {messages['receipt']}
			</Button>
		</a>
	);
};
