import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { ChevronRightIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { OrgPerson } from '../../../core/orgPerson/types';
import { Currency } from '../../currency';

export const getTrancheReasonLabel = (
	messages: { [key: string]: string },
	tranche: Pick<TObjTranche, 'reason_label' | 'ref_reason'>
): string => {
	const label = tranche.ref_reason?.includes('common.')
		? messages[`tranche_reason.${tranche.ref_reason.substring(7)}`]
		: tranche.reason_label;

	return label || '';
};

export const getTrancheChip = (tranche: TObjTranche, messages: { [key: string]: string }): ReactNode => {
	if (tranche.amount.total_amount === 0) return <Badge variant={'success'}>{messages['paid']}</Badge>;
	switch (tranche.type) {
		case 'ENT': {
			if (tranche.cashed) return <Badge variant={'success'}>{messages['paid']}</Badge>;
			return <Badge variant={'destructive'}>{messages['to_be_paid']}</Badge>;
		}
		case 'USC':
			if (tranche.cashed) return <Badge variant={'success'}>{messages['cashed']}</Badge>;
			return <Badge variant={'default'}>{messages['to_be_cashed']}</Badge>;
	}
};

export const TrancheCard = ({
	tranche,
	...props
}: {
	tranche: TObjTranche;
	athlete: OrgPerson;
	onClick?: () => void;
}) => {
	const { messages } = useI18n();

	return (
		<div onClick={props.onClick}>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 100px',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}>
				<div>
					<div className="mb-1">{getTrancheChip(tranche, messages)}</div>

					<p className="text-lg">{getTrancheReasonLabel(messages, tranche)}</p>

					<p className="text-sm text-slate-500">
						{tranche.cashed_date ? (
							<>{tranche.cashed_date}</>
						) : (
							<>{tranche.expected_cashed_date ? tranche.expected_cashed_date : ''}</>
						)}
					</p>
				</div>

				<p className="text-right text-xl font-bold">
					<Currency value={tranche.amount.total_amount} />
				</p>
			</div>

			<div
				style={{
					marginTop: 10,
					display: 'flex',
					justifyContent: 'flex-end',
				}}>
				<Button variant={'secondary'} onClick={props.onClick}>
					{messages['details']} <ChevronRightIcon size={18} className="ml-2" />
				</Button>
			</div>
		</div>
	);
};
