import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { Card } from '@/components/card';
import { Heading } from '@/components/heading';
import { useToast } from '@/components/toast';
import { Input } from '@gle/base-ui.inputs.input';
import { Loader } from '@gle/base-ui.loader';
import { useConan } from '@golee/gle-conan-tracker';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { addDocument, getDocuments, TDocument } from '../../../api/documents/entities/documents';
import { sendInstantNotification } from '../../../api/hermes/entities/hermes';
import { TPepaFileUploaded } from '../../../api/pepa/entities/pepa';
import { EmptyList } from '../../../components';
import UploaderOnPepa from '../../../components/uploader/document-uploader';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { MimeTypeIcon } from './mime-type-icon';
import { RequestDocument } from './request-document';

export const Documents = () => {
	const { toast } = useToast();
	const { trackEvent } = useConan();
	const { orgPerson } = useOrgPerson();

	const [docs, setDocs] = useState<TDocument[]>([]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fileUploaded, setFileUploaded] = useState<TPepaFileUploaded>();

	const retrieveDocuments = async (orgPersonId: string) => {
		setIsLoading(true);
		try {
			const resp = await getDocuments('org_person', orgPersonId);
			setDocs(resp.data);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		void retrieveDocuments(orgPerson.orgPersonId);
	}, []);

	const onUploadedFile = async () => {
		if (!orgPerson) {
			return;
		}

		if (!fileUploaded) {
			return;
		}

		try {
			await addDocument({
				url: fileUploaded.public_url,
				name: fileUploaded.file_name,
				mimeType: fileUploaded.mime,
				ref_entity: orgPerson.orgPersonId,
			});

			setFileUploaded(undefined);

			await retrieveDocuments(orgPerson.orgPersonId);

			try {
				await sendInstantNotification(orgPerson.orgPersonId, orgPerson.organizationId, {
					occurrenceType: 'DocumentAttachedByOrgPerson',
					ownerType: 'OrgPersonDocuments',
					metadata: {
						documentName: fileUploaded.file_name,
						documentUrl: fileUploaded.public_url,
					},
					origin: 'system',
				});
			} catch (e: any) {
				console.error(`Error sending notification: ${e.message}`);
			}

			trackEvent('family.document-uploaded', { file_name: fileUploaded.file_name });
		} catch (e: any) {
			toast({
				variant: 'destructive',
				title: 'Errore',
				description: 'Si è verificato un errore imprevisto, riprova più tardi',
			});
		}
	};

	return (
		<div className="pb-5">
			<Heading className="mb-5">Documenti</Heading>
			<div className={'mb-5'}>
				{fileUploaded ? (
					<div className={'flex gap-5'}>
						<Input
							value={fileUploaded.file_name}
							onChange={file_name => setFileUploaded({ ...fileUploaded, file_name })}
						/>
						<Button onClick={onUploadedFile}>Invia</Button>
					</div>
				) : (
					<div className={'flex gap-5'}>
						<UploaderOnPepa onUploadedFile={setFileUploaded} />
						<RequestDocument />
					</div>
				)}
			</div>

			<Card className="border-slate-100">
				{isLoading ? (
					<div className="my-5 flex justify-center">
						<Loader color="gray-lighter" />
					</div>
				) : (
					<>
						{!docs.length ? (
							<EmptyList />
						) : (
							<div className={'flex flex-col'}>
								{docs.map(doc => (
									<DocCard doc={doc} key={doc._id} />
								))}
							</div>
						)}
					</>
				)}
			</Card>

			<Outlet />
		</div>
	);
};

const DocCard = ({ doc: d }: { doc: TDocument }) => {
	return (
		<Link
			className="border-b border-solid border-slate-100 p-4 transition-colors hover:cursor-pointer hover:bg-slate-50"
			to={`/documents/${d._id}`}
			state={{ document: d }}>
			<div className="mb-1 flex justify-end gap-1">
				{!d.seenFromOrgPerson && <Badge variant={'secondary'}>Nuovo</Badge>}

				{d.signature?.status === 'pending' && <Badge variant={'warning'}>Da firmare</Badge>}

				{d.signature?.status === 'completed' && <Badge variant={'success'}>Firmato</Badge>}
			</div>

			<div className={'mt-1 flex items-center gap-5'}>
				<MimeTypeIcon mimeType={d.mimeType} />

				<div className="flex flex-col gap-1">
					<p className="text-sm">{d.name}</p>

					<p className="text-xs text-gray-400">
						{d.createdAt ? DateTime.fromISO(d.createdAt).toFormat('HH:mm dd/MM/yyyy') : ''}
					</p>
				</div>
			</div>
		</Link>
	);
};
