import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { useEffect } from 'react';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { EventCard } from './event-card';
import { useCalendarEvents } from './use-calendar-events';

export const Calendar = () => {
	const { orgPerson } = useOrgPerson();

	if (!orgPerson) {
		return <>error</>;
	}

	return <CalendarEvents orgPersonId={orgPerson.orgPersonId} organizationId={orgPerson.organizationId} />;
};

const CalendarEvents = (props: { orgPersonId: string; organizationId: string }) => {
	const { events, upcomingEvents, canLoadMore } = useCalendarEvents(props.orgPersonId, props.organizationId);

	const loadEvents = () => {
		upcomingEvents();
	};
	useEffect(() => {
		loadEvents();
	}, []);

	return (
		<div className={'hide-scrollbar'}>
			<Heading className="mb-5">Calendario</Heading>
			<div className="flex flex-col gap-5">
				{!events.length && <p className="text-center text-gray-500">Nessun evento in programma</p>}

				{events.map(event => (
					<EventCard event={event} key={event._id} orgPersonId={props.orgPersonId} />
				))}
				{canLoadMore && (
					<div style={{ paddingBlock: 20, display: 'flex', justifyContent: 'center' }}>
						<Button onClick={loadEvents} variant={'secondary'}>
							Carica altro
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
