import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { ChevronLeftIcon, DownloadIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocuments, TDocument } from '../../../api/documents/entities/documents';
import { Currency, getTrancheChip, getTrancheReasonLabel } from '../../../components';

export const TrancheDetailLayout = ({
	PrimaryCTA,
	TrancheInfo,
	tranche,
}: {
	tranche: TObjTranche;
	PrimaryCTA: React.ReactElement;
	TrancheInfo: React.ReactElement;
}) => {
	const { messages } = useI18n();
	const navigate = useNavigate();
	const [attachedDocuments, setAttachedDocuments] = useState<TDocument[]>([]);

	useEffect(() => {
		getDocuments('operation', tranche._id).then(resp => {
			setAttachedDocuments(resp.data);
		});
	}, []);

	return (
		<div>
			<div style={{ textAlign: 'center' }}>
				{getTrancheChip(tranche, messages)}

				<Heading level={2} className="my-5">
					{getTrancheReasonLabel(messages, tranche)}
				</Heading>
				<Heading className="my-5 text-4xl">
					<Currency value={tranche.amount.total_amount} />
				</Heading>

				{PrimaryCTA}
			</div>

			{attachedDocuments.length > 0 && (
				<div
					style={{
						paddingTop: 20,
						marginTop: 20,
						borderTop: '1px solid var(--gray-lightest-color)',
					}}>
					<Heading level={3} className="mb-2">
						{'Documenti allegati'}
					</Heading>

					<div className={'flex flex-wrap gap-2'}>
						{attachedDocuments.map(doc => (
							<a key={doc._id} href={doc.url} target={'_blank'}>
								<Button variant={'secondary'} size={'sm'}>
									{doc.name} <DownloadIcon size={18} className="ml-2" />
								</Button>
							</a>
						))}
					</div>
				</div>
			)}

			<div className="mt-5 pt-5">
				<Heading level={3} className="mb-2">
					{messages['details']}
				</Heading>

				{TrancheInfo}
			</div>
			<div className="mt-5 flex justify-center">
				<Button variant="secondary" onClick={() => navigate('/payments')}>
					<ChevronLeftIcon size={18} className="mr-2" /> {messages['back']}
				</Button>
			</div>
		</div>
	);
};
