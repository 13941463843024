import { Avatar } from '@/components/avatar';
import { Card } from '@/components/card';
import { Heading } from '@/components/heading';
import { Text } from '@gle/base-ui.typography.text';
import { BellIcon } from 'lucide-react';
import moment from 'moment';
import { LoadingLayout } from '../../../components';
import { useNotifications } from '../../../hooks/use-notifications';

export const Notifications = () => {
	return (
		<div className="pb-5">
			<Heading className="mb-5">Notifiche</Heading>
			<Inner />
		</div>
	);
};

const Inner = () => {
	const { notificationsList, isLoading } = useNotifications();

	if (isLoading) {
		return <LoadingLayout />;
	}

	if (notificationsList.length < 1) {
		return (
			<div className="flex items-center justify-center pt-40">
				<Text color="gray" size="s">
					Nessuna notifica per te
				</Text>
			</div>
		);
	}

	return (
		<div>
			<Card className="border-slate-100">
				{notificationsList.map(notification => (
					<NotificationItem
						title={notification.title}
						createdAt={notification.createdAt}
						key={notification._id}
					/>
				))}
			</Card>
		</div>
	);
};

export type NotificationItemProps = {
	title: string;
	createdAt: string;
};

const NotificationItem = (props: NotificationItemProps) => {
	return (
		<div className={`flex items-start space-x-4 border-b border-solid border-slate-100 p-4`}>
			<Avatar
				className={`flex h-8 w-8 items-center justify-center rounded-full border border-slate-200 bg-slate-100 shadow`}>
				<BellIcon className="h-4 w-4 text-slate-500" />
			</Avatar>

			<div className="space-y-1">
				<p className="text-sm font-normal text-slate-900">{props.title}</p>
				<p className="text-xs text-slate-500">{moment(props.createdAt).format('HH:mm | DD/MM/YYYY')}</p>
			</div>
		</div>
	);
};
