import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Text } from '@gle/base-ui.typography.text';
import { useNavigate } from 'react-router-dom';
import { ErrorIcon } from '../../assets';
import { MainLayout } from '../../components';

export const NotFound = () => {
	const navigate = useNavigate();

	return (
		<MainLayout>
			<div className="text-center">
				<ErrorIcon />

				<Heading className="mb-5">Oops!</Heading>

				<Text>La pagina che stai cercando non esiste.</Text>

				<Button onClick={() => navigate('/')} className="mt-5" variant={'secondary'}>
					Torna alla home page
				</Button>
			</div>
		</MainLayout>
	);
};
