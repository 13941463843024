import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { formatAsString } from '../../../utils/lang-utility';

export const NotCashedTrancheInfo = ({
	tranche,
	suggestGoleePayActivation,
	isLoadingPaymentInfo,
}: {
	tranche: Partial<TObjTranche>;
	suggestGoleePayActivation: boolean;
	isLoadingPaymentInfo: boolean;
}) => {
	const { messages } = useI18n();

	return (
		<>
			{!!tranche.expected_cashed_date && (
				<p className="mb-2 text-sm">
					{formatAsString(messages['to_be_paid_within_date'], {
						date: tranche.expected_cashed_date,
					})}
					<br />
					<br />
				</p>
			)}

			{!isLoadingPaymentInfo && (
				<>
					{!suggestGoleePayActivation ? (
						<div>
							<p className="text-sm">
								{messages['you_can_paid_with_your_preferred_payment_method']} <br />
								{messages['payment_will_be_cashed_by_club_message']}
								<br />
								<br />
								<span className="text-xs text-zinc-500">
									Nota: Al pagamento potrebbe essere applicata una commissione. Le commissioni possono
									variare in base alle impostazioni che ha configurato la società ed al metodo di
									pagamento selezionato. Naviga alla schermata successiva di pagamento per
									visualizzare il riepilogo dell'importo, delle commissioni e altri dettagli.
								</span>
							</p>
						</div>
					) : (
						<p className="mt-5 border-t border-solid border-gray-100 pt-5 text-sm text-gray-400">
							{tranche.type === 'ENT' && (
								<>
									Invita la tua società ad attivare il servizio di Golee Pay per poter effettuare i
									pagamenti direttamente dall'app.
								</>
							)}
						</p>
					)}
				</>
			)}
		</>
	);
};
