import { Button } from '@/components/button';
import { ChevronRightIcon } from 'lucide-react';
import { TProfile } from '../../../api/people/entities/profiles';
import { useProfiles } from '../../../context/profiles/use-profiles';

export const ProfileCard = ({ profile }: { profile: TProfile }) => {
	const { chooseProfile } = useProfiles();

	return (
		<div className="flex cursor-pointer items-center justify-between py-5" onClick={() => chooseProfile(profile)}>
			<div className="flex items-center gap-2">
				<p>
					{profile.name} {profile.surname}
				</p>
			</div>

			<Button variant="outline" size="icon">
				<ChevronRightIcon className="h-4 w-4" />
			</Button>
		</div>
	);
};
