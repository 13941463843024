import { Card } from '@/components/card';
import { LoadingLayout, Logout } from '../../../components';
import { ProfilesList } from '../../../components/athletes/profiles-list';
import { GoToManager } from '../../../components/go-to-manager';
import { InvitesList } from '../../../components/invites/';
import { LayoutBase } from '../../../components/layout/layout';
import { usePermissions } from '../../../context/permissions/use-permissions';
import { useReactNative } from '../../../hooks/use-react-native';

export const Profiles = () => {
	const { identities } = usePermissions();
	const { appVersion } = useReactNative();

	const hasManagerRole = !!identities?.find(identity => identity.role !== 'organization_person');
	const hasOnlyManagerRole = identities?.every(identity => identity.role !== 'organization_person');

	if (hasOnlyManagerRole) {
		window.location.href = window._env_.REACT_APP_MANAGER_URL;
		return <LoadingLayout />;
	}

	return (
		<LayoutBase>
			<Card className="p-4">
				<div className={'flex flex-col gap-10'}>
					<ProfilesList />
					<InvitesList />
				</div>
			</Card>
			<div className="mt-5 flex flex-col items-center gap-4">
				{hasManagerRole && <GoToManager />}
				<Logout />
			</div>
			{appVersion && (
				<div className={'absolute bottom-2 left-0 flex w-full justify-center text-neutral-500'}>
					<div>{appVersion}</div>
				</div>
			)}
		</LayoutBase>
	);
};
