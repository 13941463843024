import { LoadingLayout } from '../../../components';
import { useTranches } from '../../../context/tranches';
import { TrancheDetailCashed, TrancheDetailNotCashed } from './tranche-detail-not-cashed';

export const TrancheDetail = () => {
	const { selectedTranche } = useTranches();

	if (!selectedTranche) {
		return <LoadingLayout />;
	}

	return selectedTranche.cashed ? (
		<TrancheDetailCashed tranche={selectedTranche} />
	) : (
		<TrancheDetailNotCashed tranche={selectedTranche} />
	);
};
