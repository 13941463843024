import { Heading } from '@/components/heading';
import { ReactElement } from 'react';

export type SectionProps = {
	viewSection: ReactElement;
	formSection: ReactElement;
	headerText?: string;
	isEditing: boolean;
};

export const Section = (props: SectionProps) => {
	return (
		<div className="mt-3">
			<Heading level={2}>{props.headerText}</Heading>
			{props.isEditing ? <>{props.formSection}</> : <>{props.viewSection}</>}
		</div>
	);
};
