import { Avatar, AvatarFallback, AvatarImage } from '@/components/avatar';
import { Button } from '@/components/button';
import { EditIcon } from 'lucide-react';
import { Section } from '../../../../../../components/section';
import { SubmitButtons } from '../../../../../../components/submit-buttons/submit-buttons';

export type RenameOrgPersonViewProps = {
	name: string;
	surname: string;
	avatarUrl?: string;
	isEditing: boolean;
	onChangeRenaming: (value: boolean) => void;
	isRenaming: boolean;
};

export const RenameOrgPersonView = (props: RenameOrgPersonViewProps) => {
	return (
		<div>
			{!props.isEditing && (
				<div className="-mt-6 flex justify-end">
					{props.isRenaming ? (
						<SubmitButtons isEditing={props.isEditing} onSubmit={() => props.onChangeRenaming(false)} />
					) : (
						<Button variant={'secondary'} onClick={() => props.onChangeRenaming(true)}>
							<EditIcon size={18} className="mr-2" /> Modifica
						</Button>
					)}
				</div>
			)}

			<div className="mt-5 flex flex-col">
				<div className="mb-4 flex justify-between">
					<Avatar className="h-20 w-20">
						<AvatarImage src={props.avatarUrl} alt={`${props.name} ${props.surname}`} />
						<AvatarFallback>
							{props.name[0]}
							{props.surname[0]}
						</AvatarFallback>
					</Avatar>
				</div>

				<Section title={'Nome'}>
					<p>{props.name}</p>
				</Section>
				<Section title={'Cognome'}>
					<p>{props.surname}</p>
				</Section>
			</div>
		</div>
	);
};
