import { Button } from '@/components/button';
import { Card } from '@/components/card';
import { useConan } from '@golee/gle-conan-tracker';
import moment from 'moment';
import 'moment/locale/it';
import { useState } from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { setParticipantPresence } from './api';
import NotesModal from './notes/notes-modal';
import { CalendarEvent } from './types';

moment.locale('it');

const isEventTerminated = (event: CalendarEvent) => moment(event.start) < moment();
const isDeadlinePresenceReached = (event: CalendarEvent) =>
	event.deadlinePresence && moment(event.deadlinePresence) < moment();

export const EventCard = ({ event, orgPersonId }: { event: CalendarEvent; orgPersonId: string }) => {
	return (
		<Card>
			<div className="flex flex-col gap-5 border-b border-solid border-slate-200 p-4 pb-5">
				<div className="flex gap-5">
					<div
						className="border-solid px-2 text-center text-lg"
						style={{
							borderLeftWidth: 5,
							borderLeftColor: event.color,
						}}>
						<p className="text-sm font-light uppercase text-slate-500">
							{moment(event.start).format('MMM')}
						</p>
						<h3 className="">{moment(event.start).format('DD')}</h3>
					</div>
					<div className="flex flex-col">
						<p className="font-medium">{event.title}</p>
						<div className="flex items-center gap-3">
							<p className="text-sm font-light capitalize text-slate-500">
								{moment(event.start).format('dddd DD, HH:mm')} - {moment(event.end).format('HH:mm')}
							</p>
						</div>
						{event.place && (
							<p className="flex items-center gap-2 text-sm font-light capitalize text-slate-500">
								{event.place}
							</p>
						)}
						{event.description && <p className="text-sm text-gray-400">{event.description}</p>}
						{event.attachment && (
							<a
								href={event.attachment.url}
								target="_blank"
								className="flex items-center gap-1 text-sm text-gray-400">
								<AiOutlinePaperClip />
								{event.attachment.fileName}
							</a>
						)}
					</div>
				</div>
			</div>
			<div className="bg-slate-50 px-4 py-2">
				<EventCardActions event={event} orgPersonId={orgPersonId} />
			</div>
		</Card>
	);
};

const useEventPresence = (event: CalendarEvent, orgPersonId: string) => {
	const { trackEvent } = useConan();

	const [presence, setPresence] = useState<boolean | null>(
		event.participant ? event.participant.orgPersonPresence : null
	);
	const [note, setNote] = useState<string | undefined>(event.participant?.orgPersonPresenceNote);

	const onPresenceSet = () => {
		const _note = !presence ? '' : note;
		setNote(_note);
		setPresence(true);

		void setParticipantPresence(event._id, orgPersonId, true, _note);

		void trackEvent('calendar.event-participants-presence-set', {
			calendar_event: event._id,
			org_person: orgPersonId,
			presence: true,
			presence_note: _note,
			set_on: 'private-calendar',
		});
	};

	const onPresenceUnset = () => {
		const _note = presence ? '' : note;
		setNote(_note);
		setPresence(false);

		void setParticipantPresence(event._id, orgPersonId, false, _note);
		void trackEvent('calendar.event-participants-presence-set', {
			calendar_event: event._id,
			org_person: orgPersonId,
			presence: false,
			presence_note: _note,
			set_on: 'private-calendar',
		});
	};

	const onNoteSet = (_note?: string) => {
		if (presence === null) return;

		setNote(_note);
		void setParticipantPresence(event._id, orgPersonId, presence, _note);

		void trackEvent('calendar.event-participants-presence-set', {
			calendar_event: event._id,
			org_person: orgPersonId,
			presence,
			presence_note: _note,
			set_on: 'private-calendar',
		});
	};

	return { presence, note, onNoteSet, onPresenceSet, onPresenceUnset };
};

const EventCardActions = ({ event, orgPersonId }: { event: CalendarEvent; orgPersonId: string }) => {
	const { presence, note, onNoteSet, onPresenceSet, onPresenceUnset } = useEventPresence(event, orgPersonId);

	if (isEventTerminated(event)) {
		return <TerminatedEventPresence presence={presence} />;
	}
	if (isDeadlinePresenceReached(event)) {
		return <DeadlinePresenceReached presence={presence} />;
	}

	if (presence === null) {
		return (
			<div className="flex items-center justify-between">
				<p>Ci sarai?</p>
				<div className="flex">
					<Button
						variant={'outline'}
						onClick={() => onPresenceSet()}
						className="w-12 rounded-br-none rounded-tr-none border-r-0">
						Si
					</Button>
					<Button
						variant={'outline'}
						onClick={() => onPresenceUnset()}
						className="w-12 rounded-bl-none rounded-tl-none">
						No
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="mb-3 flex items-center justify-between">
				<p>Ci sarai?</p>

				<div className="flex">
					<Button
						variant={presence ? 'success' : 'outline'}
						onClick={() => onPresenceSet()}
						className="w-12 rounded-br-none rounded-tr-none border-r-0">
						Si
					</Button>
					<Button
						variant={!presence ? 'destructive' : 'outline'}
						onClick={() => onPresenceUnset()}
						className="w-12 rounded-bl-none rounded-tl-none">
						No
					</Button>
				</div>
			</div>

			<NotesModal presence={presence} note={note} onSave={onNoteSet} />
		</>
	);
};

const TerminatedEventPresence = ({ presence }: { presence: null | boolean; note?: string }) => {
	return (
		<div className="flex items-center justify-between">
			<div>
				{presence === null && <p className="text-gray-500">{`Partecipazione all'evento non specificata`}</p>}
				{presence === true && <p className="text-green-500">Hai partecipato a questo evento</p>}
				{presence === false && <p className="text-red-500">Non hai partecipato a questo evento</p>}
			</div>
		</div>
	);
};

const DeadlinePresenceReached = ({ presence }: { presence: null | boolean; note?: string }) => {
	return (
		<div className="flex items-center justify-between">
			<div>
				{presence === null && <p className="text-gray-500">{`Non puoi più modificare la presenza`}</p>}
				{presence === true && <p className="text-green-500">Parteciperai a questo evento</p>}
				{presence === false && <p className="text-red-500">Non parteciperai a questo evento</p>}
			</div>
		</div>
	);
};
